import React from 'react';
import {YMInitializer} from 'react-yandex-metrika';


export default function Metrika(props) {


    return (

        <div>
            <YMInitializer accounts={[88787596]} options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true
            }} version="2"/>

        </div>
    );
}