import './prices.scss';
import {React, useContext, useEffect, useState, useRef} from "react";
import Slider from "react-slick";
import StoreContext from "../context";
import emailjs from "@emailjs/browser";
import OrdinaryCircleLoader from "../loader/Loader";


const ExampleSlider = ({props}) => {
    const [show, setShow] = useState(false);
    const generalStore = useContext(StoreContext);


    useEffect(()=>{
        setShow(show);
    },[show])

    const handleSubmit = () => {
        setShow(!show);
        generalStore.setLayout(!show);

    }


    const counterClasses = ["slick-sliderrr"];
    if(show){
        counterClasses.push("active")
    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        lazyLoad: true,
        fade: true,
        swipe: true,
        className: `${counterClasses.join("")}`,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return <Slider
        {...settings}>
        {props.map(img => {
            return <div className="example" onClick={handleSubmit}>
                <div className="background"></div>
                <div className="pictureContainer">
                    <img src={img.src} alt="прозрачные роллеты" className="picture"/>
                </div>
                <div className="data">
                    <p><span>размеры:</span> {img.size}</p>
                    <p><span>цвет поликарбоната:</span> {img.colorP}</p>
                    <p><span>цвет металла:</span> {img.colorM}</p>
                    <p><span>дополнительная комплектация:</span> {img.addition}</p>
                </div>
            </div>
        })}
    </Slider>
}

const images1 = [
    {src: "https://i.ibb.co/LYmgDTy/1pleh.webp", year: "2021", size: "19,9 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price: "275 тыс. руб."},
    {src: "https://i.ibb.co/gmGrkrG/2pleh.webp", year: "2021", size: "19,9 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price: "275 тыс. руб."},
    {src: "https://i.ibb.co/gvLG1F4/3pleh.webp", year: "2021", size: "19,9 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price: "275 тыс. руб."},
    {src: "https://i.ibb.co/b6r0ytt/4pleh.webp", year: "2021", size: "19,9 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price: "275 тыс. руб."},
]

const images2 = [
    {src: "https://i.ibb.co/nDMkfst/1perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/BPhMMqD/2perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/vJHBL5J/3perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/fpJbg1g/4perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/BB3MQJS/5perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/cLxvGBp/6perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/4mpKkZf/7perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
    {src: "https://i.ibb.co/JsFzFDz/8perv.webp", year: "2021", size: "16,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price:"240 тыс. руб."},
]
const images3 = [
    {src: "https://i.ibb.co/XZ6yZhp/1hlev.webp", year: "2021", size: "11,5 кв.м.", colorP: "прозрачный", colorM: "коричный", addition: "автоматика, клавиши открывания, блок аварийного открывания", price: "180 тыс. руб."},
    {src: "https://i.ibb.co/zbWmxxZ/2hlev.webp", year: "2021", size: "11,5 кв.м.", colorP: "прозрачный", colorM: "коричный", addition: "автоматика, клавиши открывания, блок аварийного открывания", price: "180 тыс. руб."},
    {src: "https://i.ibb.co/cYJmXkd/3hlev.webp", year: "2021", size: "11,5 кв.м.", colorP: "прозрачный", colorM: "коричный", addition: "автоматика, клавиши открывания, блок аварийного открывания", price: "180 тыс. руб."},
    {src: "https://i.ibb.co/rtZhS4m/4hlev.webp", year: "2021", size: "11,5 кв.м.", colorP: "прозрачный", colorM: "коричный", addition: "автоматика, клавиши открывания, блок аварийного открывания", price: "180 тыс. руб."},
    ]

const images4 = [
    {src: "https://i.ibb.co/N3B0n4x/1novovor.webp", year: "2020", size: "12,48 кв.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания, аварийное открывание", price: "185 тыс. руб."},
    {src: "https://i.ibb.co/SxHN4nH/2novovor.webp", year: "2020", size: "12,48 кв.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания, аварийное открывание", price: "185 тыс. руб."},
    {src: "https://i.ibb.co/WcVYRxg/3novovor.webp", year: "2020", size: "12,48 кв.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания, аварийное открывание", price: "185 тыс. руб."},
    {src: "https://i.ibb.co/VwgNsd4/4novovor.webp", year: "2020", size: "12,48 кв.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания, аварийное открывание", price: "185 тыс. руб."},
    {src: "https://i.ibb.co/0MVtrNN/5novovor.webp", year: "2020", size: "12,48 кв.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания, аварийное открывание", price: "185 тыс. руб."},
    {src: "https://i.ibb.co/k9vNs9n/6novovor.webp", year: "2020", size: "12,48 кв.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания, аварийное открывание", price: "185 тыс. руб."},
    ]

const images5 = [
    {src: "https://i.ibb.co/dK9Dy7k/1bob.webp", year: "2020", size: "24,14 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты", price: "225 тыс. руб."},
    {src: "https://i.ibb.co/YNPS6tj/2bob.webp", year: "2020", size: "24,14 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты", price: "225 тыс. руб."},
    {src: "https://i.ibb.co/k3YsHc1/3bob.webp", year: "2020", size: "24,14 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты", price: "225 тыс. руб."},
    {src: "https://i.ibb.co/c87Vknt/4bob.webp", year: "2020", size: "24,14 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты", price: "225 тыс. руб."},
    ]
const images6 = [
    {src: "https://i.ibb.co/RSDS0g9/1nelzh.webp", year: "2022", size: "15,3 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания", price: "228,6 тыс. руб."},
    {src: "https://i.ibb.co/7STgZmj/2nelzh.webp", year: "2022", size: "15,3 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания", price: "228,6 тыс. руб."},
    {src: "https://i.ibb.co/BPDPG0r/3nelzh.webp", year: "2022", size: "15,3 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания", price: "228,6 тыс. руб."},
    {src: "https://i.ibb.co/LYk4Jq4/4nelzh.webp", year: "2022", size: "15,3 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, клавиши открывания", price: "228,6 тыс. руб."},
    ]

const images7 = [
    {src: "https://i.ibb.co/MSZnT7x/1maly.webp", year: "2022", size: "24,2 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "340 тыс. руб."},
    {src: "https://i.ibb.co/QjdM1VR/2maly.webp", year: "2022", size: "24,2 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "340 тыс. руб."},
    {src: "https://i.ibb.co/bKPhtfq/3maly.webp", year: "2022", size: "24,2 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "340 тыс. руб."},
    ]

const images8 = [
    {src: "https://i.ibb.co/txGxBMJ/1med.webp", year: "2021", size: "17,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price: "199 тыс.руб."},
    {src: "https://i.ibb.co/ZHBsWgJ/2med.webp", year: "2021", size: "17,1 кв.м.", colorP: "прозрачный", colorM: "коричневый", addition: "автоматика, пульты, блок аварийного открывания", price: "199 тыс.руб."},
    ]

const images9 = [
    {src: "https://i.ibb.co/Hh8F75Z/1.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},
    {src: "https://i.ibb.co/bWRKXb5/2.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},
    {src: "https://i.ibb.co/TYGzwmZ/6.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},
    {src: "https://i.ibb.co/84Q1F4W/7.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},
    {src: "https://i.ibb.co/w6yMVLL/8.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},
    {src: "https://i.ibb.co/Wg8LxNz/9.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},
    {src: "https://i.ibb.co/Hp2g2fm/10.webp", year: "2022", size: "27,33 кв.м.", colorP: "прозрачный", colorM: "антрацит", addition: "автоматика, пульты, блок аварийного открывания", price: "420 тыс.руб."},

]

export const changeEventHandlerDecorator = (fn) => {
    return (e) => {
        e.persist();
        fn(e.target.value);
    };
};

export default function Prices() {

    const [send, setSend] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [valueName, setValueName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef();

    const counterClasses = ["submitButton"];

    useEffect(()=> {
        setClicked(clicked);
        setSend(send);
        setValueName(valueName);
        setPhone(phone);
        setMessage(message);
    }, [form])

    if (clicked) {
        counterClasses.push("disabled");
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setClicked(true);
        emailjs.sendForm('service_voob34a', 'template_esaihct', form.current, 'T_LI95it-xjRg98z2')
            .then((result) => {
                console.log(result.text);
                setSend(true);
                setValueName("");
                setPhone("");
                setMessage("");
                setClicked(false);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className="prices" id="prices">
            <div className="container">
                <h3>ЦЕНЫ & ПРИМЕРЫ РАБОТ</h3>
                <h4> Примерная цена 1 квадратного метра от 11 780 руб. </h4>
                <p>Однако, цена на остекление рассчитывается в каждом случае ИНДИВИДУАЛЬНО, поскольку
                    необходимо учесть слишком много сопутствующих факторов: размеры проемов, в том числе нестандартные,
                    толщина профиля, окрашивание в особый цвет, дополнительная комплектация, необходимость произвести
                    дополнительные строительно-монтажные работы для монтирования рольставни на объекте и т.д.</p>
                <h3 style={{marginBottom:"0"}}>НАШИ ОБЪЕКТЫ</h3>
                <p>Нажатие на изображение раскрывает галерею во весь экран</p>
                <div className="works">
                    <ExampleSlider props={images9}/>
                    <ExampleSlider props={images1}/>
                    <ExampleSlider props={images2}/>
                    <ExampleSlider props={images3}/>
                    <ExampleSlider props={images4}/>
                    <ExampleSlider props={images5}/>
                    <ExampleSlider props={images6}/>
                    <ExampleSlider props={images7}/>
                    <ExampleSlider props={images8}/>
                </div>

                <h4>УЗНАТЬ ЦЕНУ</h4>
                <p>Проще всего получить ориентировочную цену -
                    это сделать приблизительные замеры Ваших проемов
                    и сбросить их нам для просчета в форме ниже:</p>

                <form ref={form} onSubmit={sendEmail}>
                    <div className="findPrice">
                        <input type="text"
                               name="name"
                               value={valueName}
                               onChange={changeEventHandlerDecorator(setValueName)}
                               placeholder="Имя *"
                               required/>
                        <input type="phone"
                               name="phone"
                               value={phone}
                               onChange={changeEventHandlerDecorator(setPhone)}
                               placeholder="Телефон *"
                               required/>
                        <textarea type="text"
                                  name="message"
                                  value={message}
                                  onChange={changeEventHandlerDecorator(setMessage)}
                                  placeholder="Цвет  полотна, цвет металла, автоматическое или ручное управление, местоположение объекта, иные дополнительные особенности"/>
                    </div>
                    <label>
                        <input type="checkbox" required/>
                        Согласие лица на обработку персональных данных в соответствии с ФЗ №152 от 27.07.2006
                    </label> <br/>
                    {!send &&
                        <button type="submit" value="send" className={counterClasses.join(" ")} onClick={()=>setClicked(true)}>
                            {!clicked ?
                                <>Отправить</> :
                               <span><OrdinaryCircleLoader/>
                                    Отправляется</span>
                            }
                        </button>
                    }
                    {send &&
                        <button type="submit" value="send" className="submitButton disabled" style={{padding:"12px 31px"}}>
                            Отправлено
                        </button>
                    }
                </form>
            </div>
        </div>
    )
}