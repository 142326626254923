import './video.scss';
import {React, useState} from "react";
import Slider from "react-slick";
import video from "./../../images/vid1.mp4"
import video2 from "./../../images/vid2.mp4"

const images = [
    {src: "https://i.ibb.co/Hh8F75Z/1.webp"},
    {src: "https://i.ibb.co/bWRKXb5/2.webp"},
    {src: "https://i.ibb.co/gDz3RPV/3.webp"},
    {src: "https://i.ibb.co/Bz3wcKk/4.webp"},
    {src: "https://i.ibb.co/FWx49tk/5.webp"},
    {src: "https://i.ibb.co/TYGzwmZ/6.webp"},
    {src: "https://i.ibb.co/84Q1F4W/7.webp"},
    {src: "https://i.ibb.co/w6yMVLL/8.webp"},
    {src: "https://i.ibb.co/Wg8LxNz/9.webp"},
    {src: "https://i.ibb.co/Hp2g2fm/10.webp"},
    {src: "https://i.ibb.co/GWVZJhT/11.webp"},
    {src: "https://i.ibb.co/rF8RdJw/12.webp"},
    {src: "https://i.ibb.co/b5W9KTM/13.webp"},
    {src: "https://i.ibb.co/5RT066H/14.webp"},
    {src: "https://i.ibb.co/p2B6CFd/15.webp"},
]


export default function Video() {

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const settings = {
        dots: false,
        arrows:true,
        infinite: true,
        lazyLoad: true,
        fade: true,
        swipe: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const settings2 = {
        dots: false,
        arrows:false,
        infinite: true,
        lazyLoad: true,
        swipe: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
    };

    const settings3 = {
        dots: true,
        arrows:true,
        infinite: true,
        lazyLoad: true,
        fade: true,
        swipe: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className='video'>
            <div className="container">
                <div className="forMobile">
                    <Slider
                        {...settings3}>
                        <iframe className="videoContent"
                                loading="lazy"
                                title="aluminservice.ru"
                                src="https://www.youtube.com/embed/9_O_HjClwLM"
                                allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope"
                                allowFullScreen></iframe>

                        {images.map(img => {
                            return <div className="pictureContainer">
                                <img src={img.src} alt="прозрачные роллеты" className="picture"/>
                            </div>
                        })}
                    </Slider>
                </div>
                <div className="forDesktop">
                    <Slider
                        asNavFor={nav2}
                        ref={slider => (setNav1(slider))}
                        {...settings}>
                        <div>
                            <video className="videoContent" id="video" controls autoPlay muted>
                                <source src={video} type="video/mp4"/>
                            </video>
                        </div>
                        <div>
                            <video className="videoContent" id="video" controls autoPlay muted>
                                <source src={video2} type="video/mp4"/>
                            </video>
                        </div>

                        {images.map(img => {
                            return <div className="pictureContainer">
                                <img src={img.src} alt="прозрачные роллеты" className="picture"/>
                            </div>
                        })}
                    </Slider>
                    <Slider
                        asNavFor={nav1}
                        ref={slider => (setNav2(slider))}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        {...settings2}>
                        <div>
                            <video className="videoContent2" controls muted>
                                <source src={video} type="video/mp4"/>
                            </video>
                        </div>
                        <div>
                            <video className="videoContent2" controls muted>
                                <source src={video2} type="video/mp4"/>
                            </video>
                        </div>
                        {images.map(img => {
                            return <div className="pictureContainer2">
                                <img src={img.src} alt="прозрачные роллеты" className="picture2"/>
                            </div>
                        })}
                    </Slider>
                </div>

            </div>
        </div>
    )
}

